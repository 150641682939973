<template>
    <div>
        <Layout class="c-labs">
            <Header>
                <Menu
                    mode="horizontal"
                    theme="dark"
                    @on-select="handleMenuSelect"
                >
                    <div>
                        <MenuItem name="logo" to="/labs">
                            Akita / Labs
                        </MenuItem>
                    </div>
                    <div>
                        <MenuItem
                            name="settings"
                            to="/labs/settings"
                            v-if="$store.getters['session/IS_AUTHENTICATED']"
                        >
                            Settings
                        </MenuItem>
                        <MenuItem
                            name="logout"
                            to="/login"
                            v-if="$store.getters['session/IS_AUTHENTICATED']"
                        >
                            Logout
                        </MenuItem>
                        <MenuItem name="login" v-else to="/login">
                            Login
                        </MenuItem>
                    </div>
                </Menu>
            </Header>
            <Content class="content-container">
                <slot name="default" />
            </Content>
        </Layout>
        <modal-queue :modals="modals" />
    </div>
</template>

<script>
import Hotkeys from "@/js/mixins/hotkeys.js";

export default {
    data() {
        return {
            modals: [],
        };
    },
    components: {
        ModalQueue: function () {
            return import("./modal-queue");
        },
    },
    mixins: [Hotkeys],
    created() {
        var _this = this;

        this.$root.$on("open-modal", (data) => {
            let existingIndex = _.findIndex(_this.modals, (item) => {
                return (
                    item.type === data.type && item.props.id === data.props.id
                );
            });

            data.open = true;
            if (existingIndex > -1) {
                _this.modals.splice(existingIndex, 1);
            }
            _this.modals.push(data);
        });

        this.$root.$on("close-modal", (data) => {
            let existingIndex = _.findIndex(_this.modals, (item) => {
                return (
                    item.type === data.type && item.props.id === data.props.id
                );
            });
            _this.modals.splice(existingIndex, 1);
        });
    },
    methods: {
        handleMenuSelect(selected) {
            if ("logout" == selected) {
                this.$store.dispatch("session/LOGOUT");
            }
        },
    },
};
</script>

<style scoped>
.layout {
    border: 1px solid #d7dde4;
    background: #f5f7f9;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}
.layout-logo {
    width: 100px;
    height: 30px;
    background: #5b6270;
    border-radius: 3px;
    float: left;
    position: relative;
    top: 15px;
    left: 20px;
}
.layout-nav {
    width: 420px;
    margin: 0 auto;
    margin-right: 20px;
}
.layout-footer-center {
    text-align: center;
}
</style>