<style>
.demo-spin-container {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: relative;
}
</style>
<template>
    <div class="demo-spin-container">
        <Spin fix :size="size"/>
    </div>
</template>
<script>
export default {
    props: {
        size: {
            type: String,
            default: "small",
        },
    },
    computed: {
        spinSize() {
            return this.size || "small";
        },
    },
};
</script>
