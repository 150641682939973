class Repository {
    constructor() {
        this.endpoint = window.akita.urls.api + "/v1/us";
    }

    store(payload, request) {
        return axios.post(this.endpoint, payload, request);
    }

    show(request) {
        return axios.get(this.endpoint, request);
    }

    update(payload, request) {
        return axios.put(this.endpoint, payload, request);
    }

    activate(payload, request) {
        return axios.post(this.endpoint, payload, request);
    }
}

module.exports = Repository;
