import Acl from "../acl.js";
/**
 * https://vuejs.org/v2/guide/custom-directive.html
 */
const Can = {
    name: "can",
    bind(el, binding, vnode) {
        /**
         * If the ACL denies then should we disable or hide
         * v-can:hide or v-can:disable
         */
        const behaviour = binding.modifiers.disable ? "disable" : "hide";

        /**
         * This will replace the element with a <!-- --> same as v-if
         */
        const commentNode = (el, node) => {
            const comment = document.createComment(" ");

            Object.defineProperty(comment, "setAttribute", {
                value: () => undefined,
            });

            node.text = " ";
            node.elm = comment;
            node.isComment = true;
            node.context = undefined;
            node.tag = undefined;
            node.data.directives = undefined;

            if (node.componentInstance) {
                node.componentInstance.$el = comment;
            }

            if (el.parentNode) {
                el.parentNode.replaceChild(comment, el);
            }
        };

        /**
         * This will simply disable the element (<button disabled>)
         */
        const disable = (element, node) => {
            element.disabled = true;
        };

        /**
         * This method will apply the desired behavior
         */
        const cannot = (behaviour, element, node) => {
            if ("disable" === behaviour) {
                disable(element, node);
            } else {
                commentNode(element, node);
            }
        };

        /**
         * Organize the possible parameters
         * 'dashboards:read'
         */
        const parameters = Array.isArray(binding.value) ?
            { gates: binding.value } :
            typeof binding.value == "object" ?
            binding.value :
            { gates: [binding.value] };

        const permissions =
            vnode.context.$store.getters["mePermissions/PERMISSIONS"];

        const acl = new Acl(parameters, permissions);

        if (!acl.can()) {
            cannot(behaviour, el, vnode);
        }
    },
    unbind(el, binding, vnode) {},
};

export default Can;