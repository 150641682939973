class Repository {
    constructor(endpoint) {
        this.endpoint = window.akita.urls.api + "/v1/segments";
    }

    remove(id, request) {
        return axios.delete(this.endpoint + "/" + id, request);
    }

    index(request) {
        return axios.get(this.endpoint, request);
    }

    show(id, request) {
        return axios.get(this.endpoint + "/" + id, request);
    }

    store(payload, request) {
        return axios.post(this.endpoint, payload, request);
    }

    update(id, payload, request) {
        return axios.put(this.endpoint + "/" + id, payload, request);
    }

    history(segmentId) {
        return new (require("@/js/repositories/base.js"))(
            window.akita.urls.api + "/v1/segments/" + segmentId + "/history"
        );
    }

    entities(segmentId) {
        return new (require("@/js/repositories/segment-entities.js"))(
            window.akita.urls.api + "/v1/segments/" + segmentId + "/entities"
        );
    }

    nps(segmentId) {
        return new (require("@/js/repositories/segment-nps.js"))(
            window.akita.urls.api + "/v1/segments/" + segmentId + "/nps"
        );
    }

    statistics(segmentId) {
        return new (require("@/js/repositories/segment-statistics.js"))(
            window.akita.urls.api + "/v1/segments/" + segmentId + "/statistics"
        );
    }
}

module.exports = Repository;
