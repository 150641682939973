// initial state
const getDefaultState = () => {
    return {
        session: JSON.parse(localStorage.getItem("session") || "{}"),
    };
};

// initial state
const state = getDefaultState();

// getters
const getters = {
    IS_AUTHENTICATED(state) {
        if (!state.session) return false;
        return !!state.session.access_token;
    },
};

// actions
const actions = {
    INITIALIZE({ commit, dispatch, state }) {
        return new Promise(function (resolve, reject) {
            if (state.session) {
                if (state.session.access_token) {
                    // If it is going to expire shortly, send a refresh
                    axios.defaults.headers.common["Authorization"] =
                        "Bearer " + state.session.access_token;
                    // update the vuex state
                    commit("SET", state.session);
                }
            }
            // resolve the promise
            resolve();
        });
    },
    STORE({ commit, dispatch }, { session }) {
        return new Promise(function (resolve, reject) {
            if (session) {
                if (session.access_token) {
                    // store the session
                    localStorage.setItem("session", JSON.stringify(session));
                    // If it is going to expire shortly, send a refresh
                    axios.defaults.headers.common["Authorization"] =
                        "Bearer " + session.access_token;
                    // update the vuex state
                    commit("SET", session);
                }
            }
            // resolve the promise
            resolve();
        });
    },
    async LOGIN_GOOGLE({ commit, dispatch }, { repository, accessToken }) {
        return new Promise(function (resolve, reject) {
            // The Promise used for router redirect in login
            repository
                .store({ access_token: accessToken })
                .then(function (response) {
                    // store the session
                    localStorage.setItem(
                        "session",
                        JSON.stringify(response.data)
                    );
                    // set the auth header to be sent with each request
                    axios.defaults.headers.common["Authorization"] =
                        "Bearer " + response.data.access_token;
                    // update the vuex state
                    commit("SET", response.data);
                    // execute the callback
                    resolve(response);
                })
                .catch(function (error) {
                    // remove session from local storage
                    localStorage.removeItem("session");
                    // set the auth header to be sent with each request
                    axios.defaults.headers.common["Authorization"] = "Bearer";
                    // update the vuex state
                    commit("SET", {});
                    // ERROR
                    reject(error);
                });
        });
    },
    LOGIN({ commit, dispatch }, { repository, credentials }) {
        return new Promise(function (resolve, reject) {
            // The Promise used for router redirect in login
            repository
                .store(credentials)
                .then(function (response) {
                    // store the session
                    localStorage.setItem(
                        "session",
                        JSON.stringify(response.data)
                    );
                    if (response.data.i) {
                        localStorage.setItem("i", response.data.i);
                    }
                    // set the auth header to be sent with each request
                    axios.defaults.headers.common["Authorization"] =
                        "Bearer " + response.data.access_token;
                    // update the vuex state
                    commit("SET", response.data);
                    // execute the callback
                    resolve(response);
                })
                .catch(function (error) {
                    // remove session from local storage
                    localStorage.removeItem("session");
                    // execute the reject
                    reject(error);
                });
        });
    },
    LOGOUT({ commit, dispatch }) {
        return new Promise(function (resolve, reject) {
            // remove session from local storage
            localStorage.removeItem("session");
            // clear all (makes line above redundant)
            localStorage.clear();
            // remove the axios default header
            delete axios.defaults.headers.common["Authorization"];
            // execute the callback
            commit("LOGOUT");
            resolve();
        });
    },
};

// mutations
const mutations = {
    LOGOUT(state) {
        Object.assign(state, getDefaultState());
    },
    SET(state, item) {
        state.session = item;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
