export default {
	watch: {
		/**
		 * Handle actual changes to the route
		 * @param {*} to
		 * @param {*} from
		 */
		$route(to, from) {
			if (this.$te("pageTitles." + _.get(to, "meta.title"))) {
				document.title =
					this.$t("pageTitles." + _.get(to, "meta.title")) +
					" - Akita";
			} else {
				document.title = "Akita";
			}
		},
	},
	created() {
		/**
		 * Listen for an explicit event
		 */
		this.$root.$on("page-title:update", (title) => {
			if (typeof title === "function") {
				document.title = title() + " - Akita";
				return;
			}
			if (this.$te("pageTitles." + title)) {
				document.title = this.$t("pageTitles." + title) + " - Akita";
			} else if (title) {
				document.title = title + " - Akita";
			} else {
				document.title = "Akita";
			}
		});
	},
};
