class Repository {
    constructor(endpoint) {
        this.endpoint = endpoint;
    }

    show(id, request) {
        return axios.get(this.endpoint + "/" + id, request);
    }

    store(payload, request) {
        return axios.post(this.endpoint, payload, request);
    }
}

module.exports = Repository;
