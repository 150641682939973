// initial state
const getDefaultState = () => {
    return {
        permissions: []
    };
};

// initial state
const state = getDefaultState();

// getters
const getters = {
    PERMISSIONS(state) {
        return state.permissions;
    }
};

// actions
const actions = {
    RESET({ commit, dispatch }) {
        commit("RESET");
    },
    async SYNC({ commit, dispatch }, { repository }) {
        const response = await repository.index();
        commit("SET", _.map(response.data.data, "name"));
    }
};

// mutations
const mutations = {
    RESET(state) {
        Object.assign(state, getDefaultState());
    },
    SET(state, items) {
        state.permissions = items;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
