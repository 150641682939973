import Pinnable from "./directives/pinnable.js";

/**
 * https://vuejs.org/v2/guide/plugins.html
 */

const DashboardWidgets = {
    install(Vue, options) {
        /** * https://vuejs.org/v2/guide/custom-directive.html */
        Vue.directive(Pinnable.name, Pinnable);
    }
};
export default DashboardWidgets;
