import mousetrap from "mousetrap";

export default {
    components: {
        Mousetrap: mousetrap,
    },
    created() {
        this.__bindHotkeys();
    },
    methods: {
        __bindHotkeys() {
            // https://medium.com/@sashika/j-k-or-how-to-choose-keyboard-shortcuts-for-web-applications-a7c3b7b408ee
            // ? - keyboard cheat sheet
            // / - set focus to the search field
            // up/down arrow keys (also j/k) - next/previous item
            // left/right arrow keys - collapse/expand tree nodes
            // Tab/Shift tab - indent/unindent for hierarchy (also focus in the form elements)
            // ESC - close any pop-up/dialog window
            // Ctrl+Enter - submit form

            const map = {
                s: {
                    n: {
                        event: "search:new",
                    },
                    t: {
                        event: "search:toggle",
                    },
                },
                g: {
                    d: {
                        event: "route:dashboards",
                    },
                    a: {
                        event: "route:accounts",
                    },
                    c: {
                        event: "route:contacts",
                    },
                    t: {
                        event: "route:activities",
                    },
                    s: {
                        event: "route:settings",
                    },
                },
                "command+s|ctrl+s": {
                    // CAN USE "mod+s" instead
                    event: "save",
                    options: {
                        preventDefault: true,
                    },
                },
            };

            const bindKeystrokes = (map, keyPrefix) => {
                _.each(
                    map,
                    _.bind((item, key) => {
                        let keystrokes = _.map(key.split("|"), (item) => {
                            return keyPrefix ? keyPrefix + " " + item : item;
                        });
                        if (item.event) {
                            window.Mousetrap.bind(keystrokes, () => {
                                let event = "hotkeys:" + item.event;
                                console.log(event);
                                this.$root.$emit(event);
                                if (_.get(item, "options.preventDefault")) {
                                    return false;
                                }
                            });
                        } else {
                            bindKeystrokes(item, key);
                        }
                    }, this)
                );
            };

            bindKeystrokes(map, null);
        },
    },
};
