export default {
    created() {
        /**
         * Make sure library is loaded
         */
        if (!window.Intercom) {
            console.error("You must initialize the Intercom library first.");
            return;
        }

        /**
         * Listen for updates to the current user
         */
        this.$on("me:updated", (user) => {
            if (!_.get(user, "us.id")) {
                return;
            }
            if (!window.Intercom) {
                return;
            }

            window.Intercom("boot", {
                app_id: window.akita.ids.intercom,
                user_id: user.id,
                email: user.email,
                name: user.firstName + " " + user.lastName,
                role_id: user.roleId,
                created_at: user.createdAt
                    ? new Date(user.createdAt).getTime() / 1000
                    : null,
                custom_launcher_selector: "#intercom-launcher",
                company: {
                    id: user.us.id,
                    name: user.us.name,
                    status_id: user.us.statusId,
                    version: user.us.version,
                    version_original: user.us.versionOriginal,
                    card_expires_at: user.us.cardExpiresAt
                        ? new Date(user.us.cardExpiresAt).getTime() / 1000
                        : null,
                    created_at: user.us.createdAt
                        ? new Date(user.us.createdAt).getTime() / 1000
                        : null,
                    subscription_ends_at: user.us.subscriptionEndsAt
                        ? new Date(user.us.subscriptionEndsAt).getTime() / 1000
                        : null,
                    trial_ends_at: user.us.trialEndsAt
                        ? new Date(user.us.trialEndsAt).getTime() / 1000
                        : null,
                },
            });
        });
    },
};
