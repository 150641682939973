class Repository {
    constructor() {
        this.endpoint = window.akita.urls.api + "/v1/me/segments";
    }

    index(request) {
        return axios.get(this.endpoint, request);
    }

    show(id, request) {
        return axios.get(this.endpoint + "/" + id, request);
    }

    history(segmentId) {
        return new (require("@/js/repositories/base.js"))(
            window.akita.urls.api + "/v1/segments/" + segmentId + "/history"
        );
    }
}

module.exports = Repository;
