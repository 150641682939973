/**
 * Valid
 * [ true, (), [], 'dashboards:read']
 */
export default class Acl {
    constructor(options, permissions) {
        this.options = options || {};
        this.permissions = permissions || [];
        this.evaluate = (gate, permissions) => {
            /**
             * A gate can be a boolean.
             */
            if (typeof gate === "boolean") {
                return gate;
            }

            /**
             * If this is an array, then ALL options must pass
             * [ 'dashboards:update:owned', dashboard.user_id === currentUser.id ]
             */
            if (Array.isArray(gate)) {
                for (var i = 0; i < gate.length; i++) {
                    if (!this.evaluate(gate[i], permissions)) {
                        return false;
                    }
                }
                return true;
            }

            /**
             * If this is a function then evaluate it
             */
            if (typeof gate == "function") {
                return gate();
            }

            /**
             * Does one of the gates exist in in permissions?
             * ex. 'dashboards:read:all' is in both
             * If so, this is allowed
             */
            if (-1 < permissions.indexOf(gate)) {
                return true;
            }

            /**
             * If this gate is 'dashboards:read' then
             * it can be satisfied by any 'dashboards:read:*'
             */
            if (_.some(permissions, (item) => item.includes(gate)).length > 0) {
                return true;
            }

            return false;
        };
    }

    can() {
        let ok = true;

        _.each(
            this.options.gates,
            _.bind(function(gate) {
                if (!this.evaluate(gate, this.permissions)) {
                    ok = false;
                }
            }, this)
        );

        return ok;
    }
}