// initial state
const getDefaultState = () => {
    return {
        createdAt: null,
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        avatar: "",
        us: {},
        phone: "",
        calendarUrl: "",
        dashboardId: null,
        cannyToken: null,
        notificationsStatusId: null,
        roleId: null,
    };
};

// initial state
const state = getDefaultState();

// getters
const getters = {
    CANNY_TOKEN(state) {
        return state.cannyToken;
    },
    CREATED_AT(state) {
        return state.createdAt;
    },
    ID(state) {
        return state.id;
    },
    FIRST_NAME(state) {
        return state.firstName;
    },
    LAST_NAME(state) {
        return state.lastName;
    },
    EMAIL(state) {
        return state.email;
    },
    AVATAR(state) {
        return state.avatar;
    },
    US(state) {
        return state.us;
    },
    PHONE(state) {
        return state.phone;
    },
    CALENDAR_URL(state) {
        return state.calendarUrl;
    },
    DASHBOARD_ID(state) {
        return state.dashboardId;
    },
    NOTIFICATIONS_STATUS_ID(state) {
        return state.notificationsStatusId;
    },
    ROLE_ID(statue) {
        return state.roleId;
    },
};

// actions
const actions = {
    RESET({ commit, dispatch }) {
        commit("RESET");
    },
    async SYNC({ commit, dispatch }, { repository }) {
        const response = await repository.show({ params: { include: ["us"] } });
        commit("SET", response.data.data);
    },
    UPDATE({ commit, dispatch }, { data }) {
        commit("SET", data);
    },
};

// mutations
const mutations = {
    RESET(state) {
        Object.assign(state, getDefaultState());
    },
    SET(state, item) {
        state.cannyToken = item.canny_token;
        state.createdAt = item.created_at;
        state.id = item.id;
        state.firstName = item.first_name;
        state.lastName = item.last_name;
        state.email = item.email;
        state.avatar = item.avatar;
        state.us = item.us;
        state.phone = item.phone;
        state.calendarUrl = item.calendar_url;
        state.dashboardId = item.dashboard_id;
        state.notificationsStatusId = item.notifications_status_id;
        state.roleId = item.role_id;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
