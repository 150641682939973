class Repository {
    constructor(endpoint) {
        this.endpoint = endpoint;
    }

    remove(id, request) {
        return axios.delete(this.endpoint + "/" + id, request);
    }

    async indexAll(params, options) {
        var config = Object.assign(
            {},
            { next: "data.links.next", pageSize: 100 },
            options
        );
        let response;
        let finished = false;
        let page = 1;
        let data = [];
        let request = {
            params: params || {},
        };
        while (!finished) {
            request.params.page = page;
            request.params.per_page = config.pageSize;
            response = await axios.get(this.endpoint, request);

            data.push(...response.data.data);
            finished = _.get(response, config.next) ? false : true;
            ++page;
        }
        return data;
    }

    index(request) {
        return axios.get(this.endpoint, request);
    }

    show(id, request) {
        return axios.get(this.endpoint + "/" + id, request);
    }

    store(payload, request) {
        return axios.post(this.endpoint, payload, request);
    }

    patch(payload, request) {
        return axios.patch(this.endpoint, payload, request);
    }

    update(id, payload, request) {
        return axios.put(this.endpoint + "/" + id, payload, request);
    }
}

module.exports = Repository;
