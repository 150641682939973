import "es6-promise/auto";

import Vue from "vue";
import store from "./stores";
import VueLocalStorage from "vue-localstorage";
import VueI18n from "vue-i18n";
import ViewUI from "view-design";
import VueMoment from "vue-moment";
import MomentTimezone from "moment-timezone";
// import wysiwyg from "vue-wysiwyg";
import VueRouter from "vue-router";
import routeConfig from "./routes/index.js";
import PermissionGate from "./plugins/permission-gate/index.js";
import DashboardWidgets from "./plugins/dashboard-widgets/index.js";
import LocalStorageCache from "./plugins/local-storage-cache/index.js";
import LayoutInternal from "@/js/components/layouts/internal.vue";
import LayoutExternal from "@/js/components/layouts/external.vue";
import LayoutLabs from "@/js/components/layouts/labs.vue";
import LoadingComponent from "@/js/components/partials/loading-component";
import App from "@/js/components/app.vue";
import AkitaTracking from "@/js/mixins/akita-tracking.js";
import Canny from "@/js/mixins/canny.js";
import Intercom from "@/js/mixins/intercom.js";
import Segment from "@/js/mixins/segment.js";
import PageTitle from "@/js/mixins/page-title.js";
import VueCodeHighlight from "vue-code-highlight";
// import VueCompositionAPI from "@vue/composition-api";

/**
 * Load Lodash
 */
window._ = require("lodash");

/**
 * Add prototype methods
 */
window.hasValue = function (v) {
    if (typeof v === "object") {
        if (v == null) {
            return false;
        }
        if (v.toString().length > 0) {
            return true;
        }
        for (let key in v) {
            if (Object.prototype.hasOwnProperty.call(v, key)) {
                return true;
            }
        }
        return false;
    }
    if (v === false) {
        return true;
    }
    if (v === 0 || v === "0") {
        return true;
    }
    return typeof v != "undefined" && v != null && v != "";
};

window.cacheOff = false;
window.cacheShort = false;

/**
 * Load Vue
 */
Vue.config.devtools = true;
Vue.config.performance = true;

/**
 * Polyfill es6 promises
 */

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.axios = require("axios");

/**
 * Append the X-Requested-With header
 * - Do we need this?
 */
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Vue-localstorage
 */
Vue.use(VueLocalStorage);

/**
 * Use VueI18n
 */
Vue.use(VueI18n);

/**
 * Use iView
 */
Vue.use(ViewUI);

/**
 * Use VueCodeHighlight
 */
Vue.use(VueCodeHighlight); //registers the v-highlight directive

/**
 * Use Vue Moment
 */
MomentTimezone.tz.setDefault("UTC");
Vue.use(VueMoment, { moment: MomentTimezone });

import messages from "./i18n/messages.js";
import iviewEnUS from "view-design/dist/locale/en-US";
import iviewNlNL from "view-design/dist/locale/nl-NL";
import iviewPtBR from "view-design/dist/locale/pt-BR";
// import { init } from "@sentry/browser";

messages["en-US"] = _.merge(iviewEnUS, messages["en-US"]);
messages["nl-NL"] = _.merge(iviewNlNL, messages["nl-NL"]);
messages["pt-BR"] = _.merge(iviewPtBR, messages["pt-BR"]);

Vue.locale = () => {};
const i18n = new VueI18n({
    locale: "en-US",
    fallbackLocale: "en-US",
    messages,
});

/**
 * Use Wysiwyg
 */
// Vue.use(wysiwyg, {
//     iconOverrides: {
//         bold: "<i class='ivu-icon icon icon-text-bold pt-3'></i>",
//         italic: "<i class='ivu-icon icon icon-text-italic pt-3'></i>",
//         underline: "<i class='ivu-icon icon icon-text-underline pt-3'></i>",
//         justifyLeft:
//             "<i class='ivu-icon icon icon-paragraph-left-align pt-3'></i>",
//         justifyCenter:
//             "<i class='ivu-icon icon icon-paragraph-center-align pt-3'></i>",
//         justifyRight:
//             "<i class='ivu-icon icon icon-paragraph-right-align pt-3'></i>",
//         headings: "<div class='pt-3'>H</div>",
//         link: "<i class='ivu-icon icon icon-hyperlink-2 pt-3'></i>",
//         code: "<i class='ivu-icon icon icon-angle-brackets pt-3'></i>",
//         orderedList: "<i class='ivu-icon icon icon-list-numbers pt-3'></i>",
//         unorderedList: "<i class='ivu-icon icon icon-list-bullets pt-3'></i>",
//         image: "<i class='ivu-icon icon icon-picture-landscape pt-3'></i>",
//         table: "<i class='ivu-icon icon icon-layout-2 pt-3'></i>",
//         removeFormat: "<i class='ivu-icon icon icon-eraser pt-3'></i>",
//     },
// });

/**
 * Use Vue Router
 */
Vue.use(VueRouter);

const router = new VueRouter(routeConfig);

/**
 * Use sentry
 */
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        Vue,
        environment: process.env.NODE_ENV,
        dsn: "https://8539ec6eca55441e82b83bf1815fc9c8@o396376.ingest.sentry.io/5249636",
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: [
                    "web.akitaapp.com",
                    "beta.akitaapp.com",
                    "localhost",
                    /^\//,
                ],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.2,
    });
}

Vue.use(PermissionGate);

Vue.use(DashboardWidgets);

Vue.use(LocalStorageCache);

// Vue.use(VueCompositionAPI);

router.beforeEach((to, from, next) => {
    ViewUI.LoadingBar.start();
    next();
});

router.afterEach(() => {
    ViewUI.LoadingBar.finish();
});

/**
 * Set up Echo to use Pusher and be available at this.$echo
 */
// import VueEcho from 'vue-echo-laravel';
// import Pusher from 'pusher-js';
// Vue.use(VueEcho, {
//     broadcaster: 'pusher',
//     key: '2f43b845e8e1bf1129f4',
//     cluster: 'us2',
//     encrypted: false,
//     namespace: ''
// });

/**
 * Import our Internal and External Layouts
 */
Vue.component("LayoutInternal", LayoutInternal);
Vue.component("LayoutExternal", LayoutExternal);
Vue.component("LayoutLabs", LayoutLabs);
Vue.component("LoadingComponent", LoadingComponent);

import "../less/app.less";

new Vue({
    /**
     * Internationalization
     */
    i18n,

    /**
     * Routing
     */
    router,

    /**
     * State
     */
    store,

    /**
     * Root DOM element
     */
    el: "#app",

    components: {
        App,
    },

    mixins: [AkitaTracking, Canny, Intercom, Segment, PageTitle],

    /**
     * Register the main Vue application component
     */
    data() {
        return {
            googleAuth: null,
            googleUser: null,
        };
    },
    watch: {
        $route(to) {
            this.$emit("route:updated", { name: to.name, data: to });
            this.$emit("event:triggered", {
                name: "navigation",
                data: _.pick(to, ["name", "path", "fullPath"]),
            });
        },
        googleUser: {
            async handler() {
                if (!this.googleUser) {
                    this.$store.dispatch("session/LOGOUT");
                    this.$store.dispatch("us/RESET");
                    this.$store.dispatch("usPreferences/RESET");
                    this.$store.dispatch("me/RESET");
                    this.$store.dispatch("scores/RESET");
                    this.$store.dispatch("mePermissions/RESET");
                    this.$store.dispatch("mePermissions/RESET");
                }
            },
            deep: true,
        },
    },
    created() {
        this.init();
    },
    methods: {
        clearCachedTraits() {
            console.log("clearCachedTraits");
            this.$localStorageCache.remove("accounts_available_columns");
            this.$localStorageCache.remove("accounts_available_filters");
            this.$localStorageCache.remove("contacts_available_columns");
            this.$localStorageCache.remove("contacts_available_filters");
        },
        async init() {
            var _this = this;
            // this.$echo.channel('my-channel').listen('my-event', (payload) => {
            //     alert(JSON.stringify(payload));
            // });

            /**
             * Initialize the Google API
             */
            window.gapi.load("client", this.initGapi);

            // Add a request interceptor
            window.axios.interceptors.request.use(
                function (config) {
                    return config;
                },
                function (error) {
                    ViewUI.LoadingBar.finish();
                    return Promise.reject(error);
                }
            );

            /**
             * Clear Columns and Filters
             */
            this.$on("clear-traits", () => {
                this.clearCachedTraits();
            });
            this.$on("segment:create", (segment) => {
                this.clearCachedTraits();
            });

            window.axios.interceptors.response.use(
                function (response) {
                    ViewUI.LoadingBar.finish();
                    return response;
                },
                function (error) {
                    return new Promise(function () {
                        ViewUI.LoadingBar.finish();
                        if (!error) {
                            return;
                        }
                        if (500 === _.get(error, "response.status")) {
                            _this.$Message.error({
                                content: "An unknown error occurred.",
                            });
                        } else if (
                            _.get(error, "response.status") === 401 &&
                            _.get(error, "config") &&
                            !_.get(error, "config.__isRetryRequest")
                        ) {
                            // if you ever get an unauthorized, logout the user
                            _this.$store.dispatch("session/LOGOUT");
                        } else if (
                            [400, 422].indexOf(
                                _.get(error, "response.status")
                            ) > -1
                        ) {
                            // convert 422  errors to messages
                            _this.$Notice.error({
                                render: (h) => {
                                    var errors = _.map(
                                        _.get(error, "response.data.errors"),
                                        function (error) {
                                            return Array.isArray(error)
                                                ? error[0]
                                                : error;
                                        }
                                    );
                                    if (_.get(error, "response.data.message")) {
                                        errors.push(
                                            _.get(
                                                error,
                                                "response.data.message"
                                            )
                                        );
                                    }

                                    return h("div", {}, [...new Set(errors)]);
                                },
                            });
                        }
                        throw error;
                    });
                }
            );

            this.$store.watch(
                function (state) {
                    return state.mePreferences;
                },
                function () {
                    _this.$i18n.locale =
                        _this.$store.getters["mePreferences/LOCALE"];
                    MomentTimezone.tz.setDefault(
                        _this.$store.getters["mePreferences/TIMEZONE"]
                    );
                },
                {
                    deep: true,
                }
            );

            this.$store.watch(
                function (state) {
                    return state.me;
                },
                function () {
                    let user = {
                        id: _this.$store.getters["me/ID"],
                        email: _this.$store.getters["me/EMAIL"],
                        firstName: _this.$store.getters["me/FIRST_NAME"],
                        lastName: _this.$store.getters["me/LAST_NAME"],
                        cannyToken: _this.$store.getters["me/CANNY_TOKEN"],
                        roleId: _this.$store.getters["me/ROLE_ID"],
                        createdAt: _this.$store.getters["me/CREATED_AT"],
                        us: {
                            cardExpiresAt:
                                _this.$store.getters["me/US"].card_expires_at,
                            id: _this.$store.getters["me/US"].id,
                            name: _this.$store.getters["me/US"].name,
                            statusId: _this.$store.getters["me/US"].status_id,
                            version: _this.$store.getters["me/US"].version,
                            versionOriginal:
                                _this.$store.getters["me/US"].version_original,
                            trialEndsAt:
                                _this.$store.getters["me/US"].trial_ends_at,
                            subscriptionEndsAt:
                                _this.$store.getters["me/US"]
                                    .subscription_ends_at,
                            createdAt: _this.$store.getters["me/US"].created_at,
                        },
                    };
                    _this.$emit("me:updated", user);
                    localStorage.setItem("__localStoragePrefix", user.id);
                },
                {
                    deep: true,
                }
            );
        },
        initGapi() {
            let _this = this;
            let formatGoogleUser = (user) => {
                if (!user) {
                    return null;
                }
                let profile = user.getBasicProfile();
                if (!profile) {
                    return null;
                }
                return {
                    image: profile.getImageUrl(),
                    firstName: profile.getGivenName(),
                    lastName: profile.getFamilyName(),
                    email: profile.getEmail(),
                };
            };
            gapi.client
                .init({
                    apiKey: window.akita.keys.googleClient,
                    discoveryDocs: [],
                    clientId: window.akita.ids.googleClient,
                    scope: "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
                })
                .then(function () {
                    _this.googleAuth = gapi.auth2.getAuthInstance();
                    _this.googleAuth.isSignedIn.listen((loggedIn) => {
                        if (loggedIn) {
                            _this.googleUser = formatGoogleUser(
                                _this.googleAuth.currentUser.get()
                            );
                        } else {
                            _this.googleUser = null;
                        }
                    });
                    _this.googleUser = formatGoogleUser(
                        _this.googleAuth.currentUser.get()
                    );
                });
        },
    },
});
