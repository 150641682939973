<template>
    <Layout ref="layout" class="c-internal">
        <Sider class="c-internal__sider" :width="80">
            <SidebarMenu/>
        </Sider>
        <Sider
            v-if="hasSubnav"
            ref="subnav"
            class="c-internal__subnav"
            v-model="subnavCollapsed"
            hide-trigger
            collapsible
            :width="300"
            :collapsed-width="15"
        >
            <slot name="subnav"/>
        </Sider>
        <subnav-toggle
            v-if="hasSubnav"
            :subnav-collapsed="subnavCollapsed"
            @toggleSubnav="subnavCollapsed = !subnavCollapsed"
        />
        <Layout
            class="c-internal__layout"
            v-on:scroll.native="$root.$emit('layout:scroll')"
        >
            <Content :style="{ minHeight: '280px' }">
                <us-status/>
                <slot name="default"/>
            </Content>
        </Layout>
        <modal-queue :modals="modals"/>
    </Layout>
</template>

<script>
import Hotkeys from "@/js/mixins/hotkeys.js";

export default {
    data() {
        return {
            modals: [],
            subnavCollapsed: false,
        };
    },
    components: {
        UsStatus: function () {
            return import("@/js/components/partials/us-status");
        },
        SidebarMenu: function () {
            return import("@/js/components/partials/sidebar-menu");
        },
        SubnavToggle: function () {
            return import("@/js/components/partials/subnav-toggle");
        },
        ModalQueue: function () {
            return import("./modal-queue");
        },
    },
    mixins: [Hotkeys],
    computed: {
        hasSubnav() {
            return _.get(this, "$route.matched[0].components.subnav")
                ? true
                : false;
        },
    },
    created() {
        var _this = this;

        this.$root.$on("open-modal", (data) => {
            let existingIndex = _.findIndex(_this.modals, (item) => {
                return (
                    item.type === data.type && item.props.id === data.props.id
                );
            });

            data.open = true;
            if (existingIndex > -1) {
                _this.modals.splice(existingIndex, 1);
            }
            _this.modals.push(data);
        });

        this.$root.$on("close-modal", (data) => {
            let existingIndex = _.findIndex(_this.modals, (item) => {
                return (
                    item.type === data.type && item.props.id === data.props.id
                );
            });
            _this.modals.splice(existingIndex, 1);
        });

        /**
         * If less than 4 weeks old then show getting started
         */
        if (
            new Date(this.$store.getters["me/CREATED_AT"]) >
            new Date(Date.now() - 604800000 * 4)
        ) {
            /**
             * Show the getting started modal
             */
            let gettingStartedCacheKey = "displayed_getting_started";

            /**
             * There are a few routes where we do not want to display
             */
            if (!this.$localStorageCache.get(gettingStartedCacheKey)) {
                let noGettingStarted = [
                    "/providers/",
                    "/oauth/",
                    "/integrations/",
                ];
                var found = false;
                _.each(noGettingStarted, (part) => {
                    if (window.location.pathname.indexOf(part) > -1) {
                        found = true;
                    }
                });
                if (!found) {
                    this.$localStorageCache.set(
                        gettingStartedCacheKey,
                        true,
                        60 * 60 * 24 * 30 // SHOW AGAIN IN 30 DAYS
                    );
                    this.$root.$emit("open-modal", {
                        type: "getting-started",
                        props: { id: null },
                    });
                }
            }
        }
    },
};
</script>
