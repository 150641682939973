import Can from "./directives/can.js";
import Cannot from "./directives/can.js";
import Acl from "./acl.js";

/**
 * https://vuejs.org/v2/guide/plugins.html
 */
const PermissionGate = {
    install(Vue, options) {
        Vue.directive(Can.name, Can);
        Vue.prototype.$can = function(...args) {
            const permissions = this.$store.getters[
                "mePermissions/PERMISSIONS"
            ];
            let temp = {...args };
            const gates = [];
            _.each(
                temp,
                _.bind(function(item) {
                    if (Array.isArray(item)) {
                        gates.push(...item);
                    } else {
                        gates.push(item);
                    }
                }, this)
            );
            const acl = new Acl({ gates: gates }, permissions);
            return acl.can();
        };
    },
};

export default PermissionGate;