/**
 * Load Vue
 */
import Vue from "vue";

/**
 * Use Vuex
 */
import Vuex from "vuex";

/**
 * Import the child modules
 */
import account from "./modules/account";
import activity from "./modules/activity";
import contact from "./modules/contact";
import interaction from "./modules/interaction";
import entityPlaybook from "./modules/entity-playbook";
import me from "./modules/me";
import mePermissions from "./modules/me-permissions";
import mePreferences from "./modules/me-preferences";
import scores from "./modules/scores";
import session from "./modules/session";
import taskTypes from "./modules/task-types";
import us from "./modules/us";
import usPreferences from "./modules/us-preferences";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        account,
        activity,
        contact,
        interaction,
        entityPlaybook,
        me,
        mePermissions,
        mePreferences,
        scores,
        session,
        taskTypes,
        us,
        usPreferences,
    },
});
