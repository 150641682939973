/**
 * https://vuejs.org/v2/guide/plugins.html
 */
const LocalStorageCache = {
    install(Vue, options) {
        Vue.prototype.$localStorageCache = {
            prefix() {
                let key = "__localStoragePrefix";
                let existing = localStorage.getItem(key);
                if (!existing || "[object Object]" == existing) {
                    let prefix = (
                        Math.random() * (+1000000 - +1) +
                        +1
                    ).toString();
                    localStorage.setItem(key, prefix);
                    return prefix;
                } else {
                    return existing;
                }
            },
            set(key, value, ttl) {
                let prefix = this.prefix();
                key = [prefix, key].join("__");
                // ttl in seconds
                const now = new Date();

                if (window.cacheOff) {
                    ttl = 0;
                } else if (window.cacheShort) {
                    ttl = 60 * 60 * (1 / 12); // 5 minutes
                }

                // `item` is an object which contains the original value
                // as well as the time when it's supposed to expire
                const item = {
                    value: value,
                    expiry: ttl ? now.getTime() + ttl * 1000 : -1,
                };
                try {
                    localStorage.setItem(key, JSON.stringify(item));
                } catch (e) {
                    console.log("Local Storage is full, Please empty data");
                    // fires When localstorage gets full
                    // you can handle error here or empty the local storage
                }
            },
            get(key) {
                let prefix = this.prefix();
                key = [prefix, key].join("__");
                const item = localStorage.getItem(key);
                // if the item doesn't exist, return null
                if (!item || "[object Object]" == item) {
                    return null;
                }
                var parsed = JSON.parse(item);
                const now = new Date();

                // NOCACHE
                if (window.cacheOff) {
                    parsed.expiry = 0;
                }

                if (-1 === parsed.expiry) {
                    return parsed.value;
                }

                // compare the expiry time of the item with the current time
                if (now.getTime() > parsed.expiry) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem(key);
                    return null;
                }

                return parsed.value;
            },
            remove(key) {
                let prefix = this.prefix();
                key = [prefix, key].join("__");
                localStorage.removeItem(key);
            },
        };
    },
};

export default LocalStorageCache;
