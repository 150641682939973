// initial state
const getDefaultState = () => {
    return {
        currency: "USD",
        valueUnit: "MRR",
        scoreThresholdGood: null,
        scoreThresholdBad: null,
        npsThresholdGood: null,
        npsThresholdBad: null,
        webhookUrl: null,
        webhookEvents: [],
    };
};

// initial state
const state = getDefaultState();

// getters
const getters = {
    CURRENCY(state) {
        return state.currency;
    },
    VALUE_UNIT(state) {
        return state.valueUnit;
    },
    SCORE_THRESHOLD_GOOD(state) {
        return state.scoreThresholdGood;
    },
    SCORE_THRESHOLD_BAD(state) {
        return state.scoreThresholdBad;
    },
    NPS_THRESHOLD_GOOD(state) {
        return state.npsThresholdGood;
    },
    NPS_THRESHOLD_BAD(state) {
        return state.npsThresholdBad;
    },
    WEBHOOK_URL(state) {
        return state.webhookUrl;
    },
    WEBHOOK_EVENTS(state) {
        return state.webhookEvents;
    },
};

// actions
const actions = {
    RESET({ commit, dispatch }) {
        commit("RESET");
    },
    async SYNC({ commit, dispatch }, { repository }) {
        const response = await repository.index({
            params: {
                include: ["preference"],
            },
        });
        commit("SET", response.data.data);
    },
};

// mutations
const mutations = {
    RESET(state) {
        Object.assign(state, getDefaultState());
    },
    SET(state, items) {
        let currency = _.find(items, function (item) {
            return item.preference.name == "currency";
        });
        state.currency = currency ? currency.value : null;
        let valueUnit = _.find(items, function (item) {
            return item.preference.name == "value_unit";
        });
        state.valueUnit = valueUnit ? valueUnit.value : null;
        let scoreThresholdGood = _.find(items, function (item) {
            return item.preference.name == "score_threshold_good";
        });
        state.scoreThresholdGood = scoreThresholdGood
            ? parseFloat(scoreThresholdGood.value)
            : null;

        let scoreThresholdBad = _.find(items, function (item) {
            return item.preference.name == "score_threshold_bad";
        });
        state.scoreThresholdBad = scoreThresholdBad
            ? parseFloat(scoreThresholdBad.value)
            : null;
        let npsThresholdGood = _.find(items, function (item) {
            return item.preference.name == "nps_threshold_good";
        });
        state.npsThresholdGood = npsThresholdGood
            ? parseFloat(npsThresholdGood.value)
            : null;
        let npsThresholdBad = _.find(items, function (item) {
            return item.preference.name == "nps_threshold_bad";
        });
        state.npsThresholdBad = npsThresholdBad
            ? parseFloat(npsThresholdBad.value)
            : null;
        let webhookUrl = _.find(items, function (item) {
            return item.preference.name == "webhook_url";
        });
        state.webhookUrl = webhookUrl ? webhookUrl.value : null;
        let webhookEvents = _.find(items, function (item) {
            return item.preference.name == "webhook_events";
        });
        state.webhookEvents = webhookEvents
            ? JSON.parse(webhookEvents.value)
            : [];
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
