class Repository {
    constructor() {
        this.endpoint = window.akita.urls.api + "/v1/me/activities-followed";
    }

    index(request) {
        return axios.get(this.endpoint, request);
    }
}

module.exports = Repository;
