// initial state
const getDefaultState = () => {
    return {
        id: null,
        cardExpiresAt: null,
        createdAt: null,
        name: null,
        planDescription: null,
        planTypeId: null,
        statusId: null,
        subscriptionNextBillingDate: null,
        subscriptionNextInvoiceAmount: null,
        subscriptionNextInvoiceDate: null,
        subscriptionEndsAt: null,
        subscriptionStatus: null,
        planFrequency: null,
        trialEndsAt: null,
        version: null,
        versionOriginal: null,
        cardLastFour: null,
        cardBrand: null,
    };
};

// initial state
const state = getDefaultState();

// getters
const getters = {
    CARD_EXPIRES_AT(state) {
        return state.cardExpiresAt;
    },
    CREATED_AT(state) {
        return state.createdAt;
    },
    ID(state) {
        return state.id;
    },
    NAME(state) {
        return state.name;
    },
    PLAN_DESCRIPTION(state) {
        return state.planDescription;
    },
    PLAN_TYPE_ID(state) {
        return state.planTypeId;
    },
    STATUS_ID(state) {
        return state.statusId;
    },
    SUBSCRIPTION_ENDS_AT(state) {
        return state.subscriptionEndsAt;
    },
    SUBSCRIPTION_NEXT_BILLING_DATE(state) {
        return state.subscriptionNextBillingDate;
    },
    SUBSCRIPTION_NEXT_INVOICE_AMOUNT(state) {
        return state.subscriptionNextInvoiceAmount;
    },
    SUBSCRIPTION_NEXT_INVOICE_DATE(state) {
        return state.subscriptionNextInvoiceDate;
    },
    SUBSCRIPTION_STATUS(state) {
        return state.subscriptionStatus;
    },
    PLAN_FREQUENCY(state) {
        return state.planFrequency;
    },
    TRIAL_ENDS_AT(state) {
        return state.trialEndsAt;
    },
    VERSION(state) {
        return state.version;
    },
    VERSION_ORIGINAL(state) {
        return state.versionOriginal;
    },
    CARD_LAST_FOUR(state) {
        return state.cardLastFour;
    },
    CARD_BRAND(state) {
        return state.cardBrand;
    },
};

// actions
const actions = {
    RESET({ commit, dispatch }) {
        commit("RESET");
    },
    UPDATE({ commit, dispatch, state }, account) {
        return new Promise(function (resolve, reject) {
            commit("SET", account);
            resolve();
        });
    },
    async SYNC({ commit, dispatch }, { repository }) {
        const response = await repository.show();
        commit("SET", response.data.data);
    },
};

// mutations
const mutations = {
    RESET(state) {
        Object.assign(state, getDefaultState());
    },
    SET(state, item) {
        state.cardExpiresAt = item.card_expires_at;
        state.createdAt = item.created_at;
        state.id = item.id;
        state.name = item.name;
        state.planDescription = item.plan_description;
        state.planTypeId = item.plan_type_id;
        state.statusId = item.status_id;
        state.subscriptionEndsAt = item.subscription_ends_at;
        state.subscriptionNextInvoiceAmount =
            item.subscription_next_invoice_amount;
        state.subscriptionNextInvoiceDate = item.subscription_next_invoice_date;
        state.planFrequency = item.plan_frequency;
        state.subscriptionStatus = item.subscription_status;
        state.trialEndsAt = item.trial_ends_at;
        state.version = item.version;
        state.versionOriginal = item.version_original;
        state.cardLastFour = item.card_last_four;
        state.cardBrand = item.card_brand;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
