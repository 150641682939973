class Repository {
    constructor(endpoint) {
        this.endpoint = endpoint;
    }

    index(payload, request) {
        request = request || {};
        return axios.post(this.endpoint, payload, request);
    }

    export(payload, request) {
        request = request || {};
        return axios.post(this.endpoint + "/export", payload, request);
    }
}

module.exports = Repository;
