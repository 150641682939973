class Repository {
    constructor() {
        this.endpoint = window.akita.urls.api + "/v1/us/preferences";
    }

    index(request) {
        return axios.get(this.endpoint, request);
    }

    remove(id, request) {
        return axios.delete(this.endpoint + "/" + id, request);
    }

    store(payload, request) {
        return axios.post(this.endpoint, payload, request);
    }
}

module.exports = Repository;
