/**
 * https://vuejs.org/v2/guide/custom-directive.html
 */
const Pinnable = {
    name: "pinnable",
    bind(el, binding, vnode) {
        /**
         * Convert the attributs of the element into an object
         * <b id="2" type="text"> => { id: "2", type: "text" }
         */
        var attributes = {};
        _.each(el.attributes, function(attribute) {
            attributes[attribute.name] = attribute.value;
        });
    },
    unbind(el, binding, vnode) {}
};

export default Pinnable;
