// initial state
const state = {
    url: null,
};

// getters
const getters = {
    URL(state) {
        return state.url ? state.url : null;
    },
};

// actions
const actions = {
    SET_URL({ commit, dispatch }, url) {
        return new Promise(function(resolve, reject) {
            commit("SET_URL", url);
            resolve();
        });
    },
    CLEAR_URL({ commit, dispatch }) {
        return new Promise(function(resolve, reject) {
            commit("SET_URL", null);
            resolve();
        });
    },
};

// mutations
const mutations = {
    SET_URL(state, url) {
        state.url = url;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
