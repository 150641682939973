<template>
    <div class="c-external">
        <slot/>
    </div>
</template>
<script>
export default {
    components: {},
};
</script>
