class Repository {
    constructor(endpoint) {
        this.endpoint = endpoint;
    }

    show(payload, request) {
        request = request || {};
        request.showLoadingBar = true;
        return axios.get(this.endpoint, payload, request);
    }
}

module.exports = Repository;
