// initial state
const getDefaultState = () => {
    return {
        taskTypes: null,
    };
};

// initial state
const state = getDefaultState();

// getters
const getters = {
    TASK_TYPES(state) {
        return state.taskTypes;
    },
};

// actions
const actions = {
    RESET({ commit, dispatch }) {
        commit("RESET");
    },
    async SYNC({ commit, dispatch }, { repository }) {
        const response = await repository.index();
        commit("SET", response.data.data);
    },
};

// mutations
const mutations = {
    RESET(state) {
        Object.assign(state, getDefaultState());
    },
    SET(state, taskTypes) {
        state.taskTypes = taskTypes;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
