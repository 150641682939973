class Repository {
    constructor(endpoint) {
        this.endpoint = endpoint;
    }

    index(request) {
        request = request || {};
        return axios.get(this.endpoint, request);
    }

    set(payload, request) {
        request = request || {};
        return axios.put(this.endpoint, payload, request);
    }
}

module.exports = Repository;
