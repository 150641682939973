class Repository {
    constructor() {
        this.endpoint = window.akita.urls.api + "/v1/account-search";
    }

    index(payload, request) {
        request = request || {};
        request.showLoadingBar = true;
        return axios.post(this.endpoint, payload, request);
    }
}

module.exports = Repository;
