class Repository {
    constructor() {
        this.endpoint = window.akita.urls.api + "/v1/login";
    }

    store(payload, request) {
        return axios.post(this.endpoint, payload, request);
    }
}

module.exports = Repository;
