const nl = {
    i: {
        datepicker: {
            weeks: {
                sun: "Zo",
                mon: "M",
                tue: "Di",
                wed: "W",
                thu: "Do",
                fri: "V",
                sat: "Za",
            },
        },
    },
    dateUnits: {
        day: "dag | dagen",
        week: "week | weken",
        month: "maand | maanden",
        year: "jaar | jaar",
    },
    entityFilters: {
        and: "en",
        addFilter: "Zoekterm Toevoegen",
        addFilterGroup: "Add Filter Group",
        dateCount: "Date Count",
        dateUnit: "Date Unit",
        deltaUnit: "Change Type",
        search: {
            placeholder: "Zoeken...",
        },
        deltaUnits: {
            units: "unit",
            percentage: "procent",
        },
        logic: {
            and: "en",
            or: "of",
        },
        timeframe: {
            unbounded: {
                label: "immer",
            },
            bounded: {
                label: "in de afgelopen",
            },
        },
        buttons: {
            done: "Voltooid",
        },
    },
};

export default nl;
