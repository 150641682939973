// initial state
const state = {
    id: null,
}

// getters
const getters = {
    ID (state) {
        return (state.id) ? parseInt(state.id) : null;
    },
}

// actions
const actions = {
    SET_ID ({ commit, dispatch }, id ) {
        return new Promise(function (resolve, reject) {  
            commit('SET_ID', id);
            resolve();
        });
    },
    CLEAR_ID ({ commit, dispatch }) {
        return new Promise(function (resolve, reject) {  
            commit('SET_ID', null);
            resolve();
        });
    },
}

// mutations
const mutations = {
    SET_ID (state, id) {  
        state.id = id;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}