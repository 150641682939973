// initial state
const getDefaultState = () => {
    return {
        timezone: "UTC",
        locale: "en-US",
    };
};

// initial state
const state = getDefaultState();

// getters
const getters = {
    TIMEZONE(state) {
        return state.timezone;
    },
    LOCALE(state) {
        return state.locale;
    },
};

// actions
const actions = {
    RESET({ commit, dispatch }) {
        commit("RESET");
    },
    async SYNC({ commit, dispatch }, { repository }) {
        const response = await repository.index();
        commit("SET", response.data.data);
    },
};

// mutations
const mutations = {
    RESET(state) {
        Object.assign(state, getDefaultState());
    },
    SET(state, items) {
        let timezone = _.find(items, { preference_id: 12 });
        let locale = _.find(items, { preference_id: 10} );

        if (timezone) {
            state.timezone = timezone.value;
        }

        if (locale) {
            state.locale = locale.value;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
