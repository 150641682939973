<template>
    <div>
        <component v-if="loaded" :is="layout">
            <router-view slot="subnav" name="subnav" />
            <router-view slot="default" />
        </component>
        <loading-app v-else />
    </div>
</template>

<script>
export default {
    data() {
        return {
            loaded: false,
        };
    },
    components: {
        LoadingApp: function () {
            return import("@/js/components/partials/loading-app");
        },
    },
    computed: {
        layout() {
            return this.$route.meta.layout || "LayoutInternal";
        },
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            let _this = this;

            this.$store.watch(
                function (state, getters) {
                    return getters["session/IS_AUTHENTICATED"];
                },
                function (to, from) {
                    if (to) {
                        _this.initializeSession();
                    } else if (
                        _this.$route.path !== "/login" &&
                        _this.$route.path !== "/register" &&
                        _this.$route.path !== "/labs/register" &&
                        _this.$route.path !== "/join-account"
                    ) {
                        _this.$router.push("/login");
                    }
                },
                {
                    deep: true,
                }
            );

            // IF THEY ARE NOT AUTHENTICATED, SEE IF THEY HAVE LOGGED IN VIA GOOGLE
            if (!this.$store.getters["session/IS_AUTHENTICATED"]) {
                this.loaded = true;
                return;
            }

            this.initializeSession();
        },
        async initializeSession() {
            /**
             * All of these vuex sync calls return promises and we want
             * them to all load before we allow the page to load
             */
            await this.$store.dispatch("session/INITIALIZE");

            const us = this.$store.dispatch("us/SYNC", {
                repository: new (require("@/js/repositories/us.js"))(),
            });
            const usPreferences = this.$store.dispatch("usPreferences/SYNC", {
                repository:
                    new (require("@/js/repositories/us-preferences.js"))(),
            });
            const me = this.$store.dispatch("me/SYNC", {
                repository: new (require("@/js/repositories/me.js"))(),
            });
            const mePermissions = this.$store.dispatch("mePermissions/SYNC", {
                repository:
                    new (require("@/js/repositories/me-permissions.js"))(),
            });
            const mePreferences = this.$store.dispatch("mePreferences/SYNC", {
                repository:
                    new (require("@/js/repositories/me-preferences.js"))(),
            });
            const scores = this.$store.dispatch("scores/SYNC", {
                repository: new (require("@/js/repositories/base.js"))(
                    window.akita.urls.api + "/v1/segments"
                ),
            });
            const taskTypes = this.$store.dispatch("taskTypes/SYNC", {
                repository: new (require("@/js/repositories/base.js"))(
                    window.akita.urls.api + "/v1/task-types"
                ),
            });

            /**
             * Gather all of the promises and wait until they complete to set loaded =true
             * which will display the page.
             */
            await Promise.all([
                us,
                usPreferences,
                me,
                mePermissions,
                mePreferences,
                scores,
                taskTypes,
            ]);

            this.loaded = true;

            if (
                /**
                 * The Account is ACTIVE so let them in
                 */
                [3, 4].indexOf(this.$store.getters["us/STATUS_ID"]) > -1
            ) {
                if (
                    /**
                     * If they are labs but not in labs, send them to labs
                     */
                    this.$store.getters["us/PLAN_TYPE_ID"] === 4 &&
                    this.$router.currentRoute.path.indexOf("/labs") !== 0
                ) {
                    this.$router.push("/labs");
                } else if (
                    // can we remove these?
                    this.$router.currentRoute.path === "/register" ||
                    this.$router.currentRoute.path === "/login" ||
                    this.$router.currentRoute.path === "/join-account"
                ) {
                    this.$router.push("/accounts-tracked");
                }
            } else if (
                /**
                 * The Account is INACTIVE so send them to register
                 */
                this.$router.currentRoute.path.indexOf("/oauth") !== 0 &&
                this.$router.currentRoute.path !== "/register" &&
                this.$router.currentRoute.path !== "/labs/register"
            ) {
                this.$router.push("/register");
            }
        },
    },
};
</script>
