import enUS from "./en-us";
import ptBR from "./pt-br";
import frFR from "./fr-fr";
import nlNL from "./nl-nl";

const messages = {
    // en: en,
    "en-US": enUS,
    "nl-NL": nlNL,
    // es: es,
    "fr-FR": frFR,
    "pt-BR": ptBR,
};

export default messages;
