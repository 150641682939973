export default {
    created() {
        /**
         * Make sure library is loaded
         */
        if (!window.analytics) {
            console.error("Segment SDK not initialized");
            return;
        }
        /**
         * Listen for updates to the current user
         */
        this.$on("me:updated", (user) => {
            if (!window.analytics) {
                return;
            }
            window.analytics.identify(user.id, {
                organization_id: user.us.id,
                name: [user.firstName, user.lastName].join(" "),
                email: user.email,
                role_id: user.roleId,
                created_at: user.createdAt,
            });
            window.analytics.group(user.us.id, {
                name: user.us.name,
                status_id: user.us.statusId,
                version: user.us.version,
                version_original: user.us.versionOriginal,
                card_expires_at: user.us.cardExpiresAt,
                created_at: user.us.createdAt,
                subscription_ends_at: user.us.subscriptionEndsAt,
                trial_ends_at: user.us.trialEndsAt,
            });
        });
        /**
         * Listen for route changges
         */
        this.$on("route:updated", (page) => {
            if (!window.analytics) {
                return;
            }
            window.analytics.page(page.name);
        });
        /**
         * Listen for route changges
         */
        this.$on("event", (event) => {
            if (!window.analytics) {
                return;
            }
            window.analytics.track(event.name, event.data);
        });

        /**
         * Track the initial page
         */
        window.analytics.load(window.akita.ids.segment);
        window.analytics.page();
    },
};
