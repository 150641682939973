// initial state
const getDefaultState = () => {
    return {
        scores: [],
    };
};

// initial state
const state = getDefaultState();

// getters
const getters = {
    SCORES(state) {
        return state.scores;
    },
};

// actions
const actions = {
    RESET({ commit, dispatch }) {
        commit("RESET");
    },
    async SYNC({ commit, dispatch }, { repository }) {
        var data;
        try {
            data = await repository.indexAll({ q: ["type_id[eq]=3"] });
        } catch (error) {
            data = {};
        }
        commit("SET", data);
    },
};

// mutations
const mutations = {
    RESET(state) {
        Object.assign(state, getDefaultState());
    },
    SET(state, items) {
        let scores = _.map(items, (item) => {
            return {
                id: item.id,
                name: item.name,
                threshold_bad: item.threshold_bad,
                threshold_good: item.threshold_good,
            };
        });
        state.scores = scores;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
