export default {
    created() {
        /**
         * Make sure library is loaded
         */
        if (!window.Akita) {
            console.error("No id provided for akita tracking code.");
            return;
        }

        /**
         * Listen for updates to the current user
         */
        this.$on("me:updated", (user) => {
            if (!_.get(user, "us.id")) {
                return;
            }
            if (!window.Akita) {
                return;
            }

            window.Akita("contact", user.id, user.us.id, {
                email: user.email,
                first_name: user.firstName,
                last_name: user.lastName,
                role_id: user.roleId,
                created_at: user.createdAt,
            });

            window.Akita("account", user.us.id, {
                name: user.us.name,
                status_id: user.us.statusId,
                version: user.us.version,
                version_original: user.us.versionOriginal,
                card_expires_at: user.us.cardExpiresAt,
                created_at: user.us.createdAt,
                subscription_ends_at: user.us.subscriptionEndsAt,
                trial_ends_at: user.us.trialEndsAt,
            });
        });

        /**
         * Listen for route changges
         */
        this.$on("route:updated", (page) => {
            if (!window.Akita) {
                return;
            }
            window.Akita("page", page.name);
        });

        /**
         * Listen for route changges
         */
        this.$on("event:triggered", (event) => {
            if (!window.Akita) {
                return;
            }
            window.Akita("event", event.name, event.data);
        });
    },
};
