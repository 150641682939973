export default {
    created() {
        /**
         * Make sure library is loaded
         */
        if (!window.Canny) {
            console.error("You must initialize the Canny library first.");
            return;
        }
        /**
         * Listen for updates to the current user
         */
        this.$on("me:updated", (user) => {
            window.Canny("identify", {
                appID: window.akita.ids.canny,
                user: {
                    // Replace these values with the current user's data
                    email: user.email,
                    name: [user.firstName, user.lastName].join(" "),
                    id: user.id,

                    // These fields are optional, but recommended:
                    avatarURL: user.avatar,
                    created: user.created_at
                        ? new Date(user.created_at).toISOString()
                        : null,
                    companies: [{ id: user.us.id, name: user.us.name }],
                },
            });
        });

        window.Canny("initChangelog", {
            appID: window.akita.ids.canny,
            position: "bottom",
            align: "left",
        });
    },
};
